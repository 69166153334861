const theme = {
    colors: {
        primary: '#3D63AE',
        bg: '#fff',
        black: '#000',
        greyLight: '#EBEDF2',
        greyBlue: '#a2bce2',
        grey: '#595C62',
        greyDark: '#303643',
        greyDarker: '#1c252b',
    },
    maxWidth: '1000px',
    maxWidthText: '720px',
    breakpoints: {
        xs: '400px',
        s: '600px',
        m: '900px',
        l: '1200px',
    },
};

export default theme;
