import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

class Header extends Component {
    render() {
        return (
            <header className="header-main dark middle">
                <nav>
                    <div className="max-width-l">
                        <a href="index.html" className="logo" rel="home">
                            <img src="/content/spear.png" />
                            <span>Spell Codes</span>
                        </a>
                        <div className="nav-toggle" />
                        <ul className="inline" id="navigation">
                            <li>
                                <a href="index.html">Prodotti</a>
                            </li>
                            <li>
                                <a href="pricing.html">Lavori</a>
                            </li>
                            <li>
                                <a href="pricing.html">Azienda</a>
                            </li>
                            <li>
                                <a href="about.html">Articoli</a>
                            </li>
                        </ul>
                        <ul className="inline right">
                            <li>
                                <a
                                    href="login.html"
                                    className="button button-secondary button-m full-width-tablet"
                                    role="button"
                                >
                                    Accedi
                                </a>
                                <a
                                    href="signup.html"
                                    className="button button-secondary button-m full-width-tablet"
                                    role="button"
                                >
                                    Iscriviti
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;

Header.propTypes = {
    invert: PropTypes.bool
};

Header.defaultProps = {
    invert: false
};
