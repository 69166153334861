import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

class Footer extends Component {
    componentDidMount() {
        require('./scripts');
    }

    render() {
        return (
            <footer className="footer-main bg-image-hero dark overlay-shape-06">
                <div className="padding">
                    <div className="center margin-bottom max-width-m">
                        <h3>Grow your startup the right way.</h3>
                        <p className="paragraph">
                            Our tools are packed with epic features. So you can
                            focus on your most important work.
                        </p>
                    </div>
                    <div className="center margin-bottom max-width-l">
                        <div className="row margin-bottom min-two-columns">
                            <div className="col-one-fourth">
                                <i className="feature-icons material-icons bg-gradient-pink">
                                    format_shapes
                                </i>
                                <h6>Minimal Design</h6>
                            </div>
                            <div className="col-one-fourth">
                                <i className="feature-icons material-icons bg-gradient-cyan">
                                    network_check
                                </i>
                                <h6>Rocket Fast</h6>
                            </div>
                            <div className="col-one-fourth">
                                <i className="feature-icons material-icons bg-gradient-green">
                                    line_style
                                </i>
                                <h6>Framework</h6>
                            </div>
                            <div className="col-one-fourth">
                                <i className="feature-icons material-icons bg-gradient-indigo">
                                    check
                                </i>
                                <h6>HTML5 Valid</h6>
                            </div>
                        </div>
                    </div>
                    <div className="card card-content dark margin-bottom max-width-l">
                        <form className="row reduce-spacing">
                            <div className="col-two-thirds middle">
                                <h3>Ready to get started?</h3>
                                <p className="lead">
                                    Start your free 15-day trial today.
                                </p>
                            </div>
                            <div className="col-one-third middle">
                                <a
                                    href="signup.html"
                                    className="button button-primary button-l extrude"
                                    role="button"
                                >
                                    Parliamone
                                </a>
                            </div>
                        </form>
                    </div>
                    <div className="row center-desktop max-width-l">
                        <div className="col-two-fifths">
                            <h6>Opalin ®</h6>
                            <p>
                                We're a completely remote company, working
                                across twenty countries with over 20,000
                                customers.
                            </p>
                        </div>
                        <div className="col-one-fifth">
                            <h6>Info</h6>
                            <ul className="blank">
                                <li>
                                    <a href="#">Getting Started</a>
                                </li>
                                <li>
                                    <a href="#">Resources</a>
                                </li>
                                <li>
                                    <a href="#">Design</a>
                                </li>
                                <li>
                                    <a href="#">Tutorials</a>
                                </li>
                                <li>
                                    <a href="#">Pricing</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-one-fifth">
                            <h6>Support</h6>
                            <ul className="blank">
                                <li>
                                    <a href="#">Documentation</a>
                                </li>
                                <li>
                                    <a href="#">Requirements</a>
                                </li>
                                <li>
                                    <a href="#">License</a>
                                </li>
                                <li>
                                    <a href="#">Updates</a>
                                </li>
                                <li>
                                    <a href="#">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-one-fifth">
                            <h6>Connect</h6>
                            <ul className="blank">
                                <li>
                                    <a href="#">Twitter</a>
                                </li>
                                <li>
                                    <a href="#">Facebook</a>
                                </li>
                                <li>
                                    <a href="#">Instagram</a>
                                </li>
                                <li>
                                    <a href="#">Medium</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className="copyright">
                    <span>HTML Template by </span>
                    <a
                        href="https://uiuxassets.com/"
                        rel="noopener"
                        target="_blank"
                    >
                        UI/UX Assets
                    </a>
                    <span> - © 2018, all rights reserved.</span>
                </p>
            </footer>
        );
    }
}

export default Footer;

Footer.propTypes = {
    children: PropTypes.node.isRequired
};
