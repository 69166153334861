/* eslint no-unused-expressions: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { injectGlobal } from 'emotion';
import { ThemeProvider } from 'emotion-theming';
import 'typeface-lora';
import 'typeface-source-sans-pro';
import { Header, Footer, SEO } from 'components';
import { theme, reset, symbiosis } from 'styles';

injectGlobal`
    ${reset}
    ${symbiosis}
`;

const PureLayout = ({ children, data }) => (
    <ThemeProvider theme={theme}>
        <>
            <SEO />
            <Header />
            {children}
            <Footer>
                <div
                    dangerouslySetInnerHTML={{
                        __html: `
                            ${data.prismicHomepage.data.footer.html}
                        `
                    }}
                />
            </Footer>
        </>
    </ThemeProvider>
);

class Layout extends Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query LayoutQuery {
                        prismicHomepage {
                            data {
                                footer {
                                    html
                                }
                            }
                        }
                    }
                `}
                render={data => <PureLayout {...this.props} data={data} />}
            />
        );
    }
}

export default Layout;

PureLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    data: PropTypes.object.isRequired
};
