'use strict';

if (typeof document !== 'undefined') {
    var navToggle = document.querySelector('.nav-toggle');
    var body = document.getElementsByTagName('body')[0];
    var headerMain = document.querySelector('.header-main');

    navToggle.onclick = function() {
        body.classList.toggle('no-scroll');
        headerMain.classList.toggle('active');
    };

    function debounce(func, wait, immediate) {
        var timeout;

        return function executedFunction() {
            var context = this;
            var args = arguments;

            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };

            var callNow = immediate && !timeout;

            clearTimeout(timeout);

            timeout = setInterval(later, wait);

            if (callNow) func.apply(context, args);
        };
    }

    const asdf = debounce(function() {
        function getBodyScrollTop() {
            const el = document.scrollingElement || document.documentElement;
            return el.scrollTop;
        }

        if (getBodyScrollTop() >= 50) {
            var header = document.getElementsByTagName('header')[0];
            header.style.backgroundColor = 'hsla(224, 43%, 12%, 90%)';

            var nav = document.getElementsByTagName('nav')[0];
            nav.style.padding = '0.5rem 1rem';

            var ul = document.getElementById('navigation');
            ul.style.fontSize = '1rem';
        } else {
            var header = document.getElementsByTagName('header')[0];
            header.style.backgroundColor = 'transparent';

            var nav = document.getElementsByTagName('nav')[0];
            nav.style.padding = '1rem';

            var ul = document.getElementById('navigation');
            ul.style.fontSize = '1.2rem';
        }
    }, 150);

    window.addEventListener('load', asdf);
    window.addEventListener('load', function() {
        body.classList.remove('preload');
    });
}
